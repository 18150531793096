.intro-title
{
    font-family: ClanPro-News;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    -webkit-font-smoothing: antialiased;

    color: #000000;

    padding-top: 20px;
    padding-bottom: 20px;

    text-align: center;
}

.intro-title-link
{
    text-decoration: underline;
}

.intro-title-link:hover
{
    color: #000000;
    cursor: pointer;
}

.intro-item
{
    position: relative;

    margin-bottom: 30px;
    width: 100%;
    height: 240px;

    border: none;
    padding: 0;
    padding-left: 0px;
    padding-right: 0px;

    /* Safari fix */
    margin-left: 0px;
    margin-right: 0px;
}

.intro-item:hover
{
    cursor: pointer;
}

.intro-item-image
{
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
}

.intro-item-canvas
{
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
}

.intro-item:hover .intro-item-border
{
    opacity: 1;
}

.intro-item-border
{
    position: absolute;
    
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    border: 4px solid #da291c;
    
    transition: all .25s ease-out;
}

.intro-item-label
{
    position: absolute;
    left: 0;
    top: 0;
    height: 30px;

    padding-left: 14px;
    padding-right: 14px;
    padding-top: 9px;

    background-color: #101820;
    color: #ffffff;

    font-family: ClanPro-Medium;
    font-size: 11px;
    font-weight: 500;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    display: block;
}

.intro-item-label::after
{
    width: 8px;
    height: 15px;
    right: -7px;
    top: 0px;
    content: "";
    position: absolute;
    display: block;

    background-color: #101820;
}

.intro-item-label-highlight
{
    position: absolute;
    left: 0;
    top: 0;
    height: 30px;

    padding-left: 14px;
    padding-right: 14px;
    padding-top: 9px;

    background-color: #da291c;
    color: #ffffff;

    font-family: ClanPro-Medium;
    font-size: 11px;
    font-weight: 500;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    display: block;
}

.intro-item-label-highlight::after
{
    width: 8px;
    height: 15px;
    right: -7px;
    top: 0px;
    content: "";
    position: absolute;
    display: block;

    background-color: #da291c;
}

.intro-item-displayname
{
    position: absolute;
    right: 0;
    bottom: -4.15px;

    padding-left: 11px;
    padding-right: 11px;
    padding-top: 7px;

    background-color: #ffffff;
    color: #000000;

    font-family: ClanPro-Medium;
    font-size: 11px;
    font-weight: 500;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    display: table-cell;
    vertical-align: bottom;
}