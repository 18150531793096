.favourite-title
{
    font-family: ClanPro-Medium;
    font-size: 64px;
    font-weight: 700;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    line-height: 96px;

    color: #101820;
}

.favourite-description
{
    font-family: ClanPro-News;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    line-height: 26px;

    color: #788490;
}

.favourite-item
{
    position: relative;

    margin-bottom: 30px;
    width: 100%;
    height: 240px;

    border: none;
    padding: 0;
    padding-left: 0px;
    padding-right: 0px;

    /* Safari fix */
    margin-left: 0px;
    margin-right: 0px;
}

.favourite-item:hover
{
    cursor: pointer;
}

.favourite-item-canvas
{
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
}

.favourite-item-displayname
{
    position: absolute;
    right: 0;
    bottom: -4.15px;

    padding-left: 11px;
    padding-right: 11px;
    padding-top: 7px;

    background-color: #ffffff;
    color: #000000;

    font-family: ClanPro-Medium;
    font-size: 11px;
    font-weight: 500;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    
    display: table-cell;
    vertical-align: bottom;
}

.favourite-delete
{
    border: none;

    background-color: #d92a1c;

    position: absolute;
    top: 0px;
    right: 0px;
    width: 48px;
    height: 48px;
    
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    background-image: url('../image/button-trash.png');
    background-repeat: no-repeat;
    background-size: 48px 48px;
    background-position: left;
}

.favourite-delete:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #101820;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    background-image: url('../image/button-trash-hover.png');
    background-repeat: no-repeat;
    background-size: 48px 48px;
    background-position: left;
}
  
.favourite-delete:hover
{
    cursor: pointer;
}
  
.favourite-delete:hover:after
{
    width: 100%;
}