.function-button-save-mobile
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    line-height: 14px;

    color: #FFFFFF;

    border: none;

    background-color: #e2b6b4;

    display: block;
    position: absolute;
    bottom: 16px;
    right: 0px;
    width: 140px;
    height: 48px;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;

    /* Safari fix */
    margin-left: 0px;
    margin-right: 0px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.function-button-save-mobile:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #d92a1c;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.function-button-save-mobile:hover
{
    color: #fff;

    cursor: pointer;
}

.function-button-save-mobile:hover:after
{
    width: 100%;
}

.function-button-save-mobile span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.function-button-save-mobile span::before
{
    content: "\e912";

    padding-right: 6px;

    font-family: Icons;
    font-size: 14px;
    text-align: left;
    line-height: 14px;
}

.function-button-save-tablet
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    line-height: 14px;

    color: #FFFFFF;

    border: none;

    background-color: #e2b6b4;

    display: block;
    position: absolute;
    bottom: 16px;
    right: 280px;
    width: 56px;
    height: 48px;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;

    /* Safari fix */
    margin-left: 0px;
    margin-right: 0px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.function-button-save-tablet:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #d92a1c;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.function-button-save-tablet:hover
{
    color: #fff;

    cursor: pointer;
}

.function-button-save-tablet:hover:after
{
    width: 100%;
}

.function-button-save-tablet span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.function-button-save-tablet span::before
{
    content: "\e912";

    padding-right: 6px;

    font-family: Icons;
    font-size: 18px;
    text-align: left;
    line-height: 18px;
}

.function-button-save-desktop
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    line-height: 14px;

    color: #FFFFFF;

    border: none;

    background-color: #e2b6b4;

    display: block;
    position: absolute;
    bottom: 16px;
    right: 360px;
    width: 180px;
    height: 48px;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;

    /* Safari fix */
    margin-left: 0px;
    margin-right: 0px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.function-button-save-desktop:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #d92a1c;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.function-button-save-desktop:hover
{
    color: #fff;

    cursor: pointer;
}

.function-button-save-desktop:hover:after
{
    width: 100%;
}

.function-button-save-desktop span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.function-button-save-desktop span::before
{
    content: "\e912";

    padding-right: 6px;

    font-family: Icons;
    font-size: 14px;
    text-align: left;
    line-height: 14px;
}

.function-button-downloads-mobile
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    line-height: 18px;
    
    color: #FFFFFF;

    border: none;

    /* background-color: #96b1b6; */
    background-color: #101820;

    display: block;
    position: absolute;
    bottom: 16px;
    right: 0px;
    width: 140px;
    height: 48px;

    float: right;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;

    margin-left: 0px;
    margin-right: 0px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.function-button-downloads-mobile:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #d92a1c;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.function-button-downloads-mobile:hover
{
    color: #fff;

    cursor: pointer;
}
  
.function-button-downloads-mobile:hover:after
{
    width: 100%;
}

.function-button-downloads-mobile span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.function-button-downloads-tablet
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    line-height: 18px;
    
    color: #FFFFFF;

    border: none;

    /* background-color: #96b1b6; */
    background-color: #101820;

    display: block;
    position: absolute;
    bottom: 16px;
    right: 140px;
    width: 140px;
    height: 48px;

    float: right;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;

    margin-left: 0px;
    margin-right: 0px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.function-button-downloads-tablet:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #d92a1c;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.function-button-downloads-tablet:hover
{
    color: #fff;

    cursor: pointer;
}
  
.function-button-downloads-tablet:hover:after
{
    width: 100%;
}

.function-button-downloads-tablet span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.function-button-downloads-desktop
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    line-height: 14px;

    color: #FFFFFF;

    border: none;

    /* background-color: #96b1b6; */
    background-color: #101820;

    display: block;
    position: absolute;
    bottom: 16px;
    right: 180px;
    width: 180px;
    height: 48px;

    float: right;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;

    margin-left: 0px;
    margin-right: 0px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.function-button-downloads-desktop:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #d92a1c;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.function-button-downloads-desktop:hover
{
    color: #fff;

    cursor: pointer;
}
  
.function-button-downloads-desktop:hover:after
{
    width: 100%;
}

.function-button-downloads-desktop span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.function-button-downloads-desktop span::before
{
    content: "\e919";

    padding-right: 6px;

    font-family: Icons;
    font-size: 14px;
    text-align: left;
    line-height: 14px;
}

.function-button-productinfo-mobile
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    line-height: 18px;

    color: #FFFFFF;

    border: none;

    background-color: #788490;

    display: block;
    position: absolute;
    bottom: 16px;
    right: 0px;
    width: 140px;
    height: 48px;

    float: right;

    padding-left: 20px;
    padding-right: 0px;
    padding-top: 15px;
    padding-bottom: 15px;

    margin-left: 0px;
    margin-right: 0px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.function-button-productinfo-mobile:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #d92a1c;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.function-button-productinfo-mobile:hover
{
    color: #fff;

    cursor: pointer;
}
  
.function-button-productinfo-mobile:hover:after
{
    width: 100%;
}

.function-button-productinfo-mobile span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.function-button-productinfo-desktop
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    line-height: 14px;

    color: #FFFFFF;

    border: none;

    background-color: #788490;

    display: block;
    position: absolute;
    bottom: 16px;
    right: 0px;
    width: 180px;
    height: 48px;

    float: right;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;

    margin-left: 0px;
    margin-right: 0px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.function-button-productinfo-desktop:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #d92a1c;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.function-button-productinfo-desktop:hover
{
    color: #fff;

    cursor: pointer;
}
  
.function-button-productinfo-desktop:hover:after
{
    width: 100%;
}

.function-button-productinfo-desktop span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.function-button-productinfo-desktop span::before
{
    content: "\e929";

    padding-right: 6px;

    font-family: Icons;
    font-size: 14px;
    text-align: left;
}