.canvas-container
{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
}

.canvas-container-hide
{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0%;
    height: 0%;
}

.canvas
{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    
    width: 100%;
    height: 100%;
}

.canvas-not-supported
{
    position: absolute;
    top: 160px;

    padding: 18px;
}