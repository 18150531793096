.loading-container
{
    height: 100%;

    background-color: #ffffff;
}

.loading-intro
{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-photo
{
    position: absolute;
    left: 0;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-rotate-intro
{
    width: 50px;
    height: 50px;

    background-image: url('../image/icon-loading-intro.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    animation: loading-rotate 2s infinite;
}

.loading-rotate-photo
{
    width: 50px;
    height: 50px;

    background-image: url('../image/icon-loading.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    animation: loading-rotate 2s infinite;
}

@keyframes loading-rotate
{
    100% {transform: rotate(360deg);}
}

.loading-rotate-download-container
{
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;

    width: 50px;
    height: 80px;
}

.loading-rotate-download
{
    width: 50px;
    height: 50px;

    background-image: url('../image/icon-loading-intro.png');
    background-repeat: no-repeat;
    background-size: 50px 50px;
    background-position: center;

    animation: loading-rotate 2s infinite;
}