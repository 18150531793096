.start-section
{
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    min-height: 540px;

    background-color: #eee;
    background-image: url('../image/wallpaper3.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;    
}

.start-container
{
    display: table-cell;
    vertical-align: middle;
}

.start-column
{
    float: none;
    margin: 0 auto;

    margin-top: 15px;
    margin-bottom: 15px;
}

.start-label-highlight
{
    position: absolute;
    display: block;

    left: 14px;
    top: 0;
    height: 30px;

    padding-left: 14px;
    padding-right: 14px;
    padding-top: 7px;

    background-color: #da291c;
    color: #ffffff;

    font-family: ClanPro-Medium;
    font-size: 11px;
    font-weight: 500;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;
}

.start-label-highlight::after
{
    position: absolute;
    display: block;

    width: 8px;
    height: 15px;
    right: -7px;
    top: 0px;
    
    content: "";

    background-color: #da291c;
}

.start-title
{
    padding-top: 40px;

    font-family: ClanPro-Bold;
    font-size: 72px;
    -webkit-font-smoothing: antialiased;

    text-align: left;
    color: #ffffff;
}

@media (max-width: 768px)
{
    .start-title
    {
        font-size: 48px;
    }
}

.start-text
{
    padding-top: 20px;

    font-family: ClanPro-News;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    -webkit-font-smoothing: antialiased;

    text-align: left;
    color: #ffffff;
}

.start-text-link
{
    padding-top: 20px;

    font-family: ClanPro-News;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    -webkit-font-smoothing: antialiased;
    text-decoration: underline;

    text-align: left;
    color: #ffffff;
}

.start-text-link:hover
{
    cursor: pointer;
    color: #ffffff;
}

.start-button-large
{
    font-family: ClanPro-News;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    color: #FFFFFF;

    border: none;
    outline: none;

    background-color: #d92a1c;

    display: block;
    position: relative;
    height: 48px;
    padding-left: 20px;
    padding-right: 20px;

    /* Safari fix */
    margin-left: 0px;
    margin-right: 24px;
    margin-top: 24px;

    float: left;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}

.start-button-large:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #101820;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.start-button-large:hover
{
    color: #fff;

    cursor: pointer;
}
  
.start-button-large:hover:after
{
    width: 100%;
}

.start-button-large span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.start-button-large-arrow
{
    font-family: ClanPro-News;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    color: #FFFFFF;

    border: none;
    outline: none;

    background-color: #d92a1c;

    display: block;
    position: relative;
    height: 48px;
    padding-left: 20px;
    padding-right: 20px;

    /* Safari fix */
    margin-left: 0px;
    margin-right: 24px;
    margin-top: 24px;

    float: left;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}

.start-button-large-arrow:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #101820;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.start-button-large-arrow:hover
{
    color: #fff;

    cursor: pointer;
}
  
.start-button-large-arrow:hover:after
{
    width: 100%;
}

.start-button-large-arrow span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.start-button-large-arrow span::before
{
    content: "\e91b";

    width: 28px;
    height: 24px;

    padding-right: 10px;

    font-family: Icons;
    font-size: 14px;
    text-align: left;
}

.start-button-large-arrow-continue-container
{
    display: block;
    position: relative;
}

.start-button-large-arrow-continue
{
    font-family: ClanPro-News;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    color: #FFFFFF;

    border: none;
    outline: none;

    background-color: #64789a;

    display: inline-block;
    position: relative;
    height: 48px;
    padding-left: 20px;
    padding-right: 20px;

    margin-left: 0px;
    margin-right: 24px;
    margin-top: 24px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}

.start-button-large-arrow-continue:after
{
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 0;
    height: 48px;
    
    background-color: #101820;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.start-button-large-arrow-continue:hover
{
    color: #fff;

    cursor: pointer;
}
  
.start-button-large-arrow-continue:hover:after
{
    width: 100%;
}

.start-button-large-arrow-continue span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.start-button-large-arrow-continue span::before
{
    content: "\e91b";

    width: 28px;
    height: 24px;

    padding-right: 10px;

    font-family: Icons;
    font-size: 14px;
    text-align: left;
}

.start-autosave-canvas
{
    display: inline-block;
    position: absolute;
    bottom: 100%;
    left: 0px;

    width: 100%;
    height: 48px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
}