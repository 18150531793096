.debug
{
    overflow-y: scroll;

    position: absolute;
    right: 0px;
    top: 134px;
    width: max-content;
    max-height: calc(100vh - 236px);

    color: #FFFFFF;
    background-color: #0000FF;

    font-family: Courier New;

    white-space: pre-wrap;
}