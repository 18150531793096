.configurator-backbutton
{
    display: block;
    position: relative;
    float: left;
    
    width: 54px;
    height: 54px;

    margin-right: 8px;

    border: none;

    background-color: #ffffff;
    background-image: url('../image/button-arrow-left-alt2.png');
    background-repeat: no-repeat;
    background-size: 54px 54px;
    background-position: left;
    
    transition: all .25s ease-out;
}

.configurator-backbutton:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #d92a1c;
    background-image: url('../image/button-arrow-left-hover2.png');
    background-repeat: no-repeat;
    background-size: 54px 54px;
    background-position: left;

    transition: all .25s ease-out;
}

.configurator-backbutton:hover
{
    cursor: pointer;
}

.configurator-backbutton:hover:after
{
    width: 100%;
}

.configurator-title
{
    display: block;
    position: relative;
    float: left;

    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    
    height: 54px;

    color: #ffffff;
    background-color: #101820;

    font-family: ClanPro-Medium;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.configurator-info
{
    display: block;
    position: relative;
    float: left;

    padding-top: 16px;

    text-align: center;

    width: 54px;
    height: 54px;

    background-color: #ffffff;

    color: #000000;

    font-family: ClanPro-Medium;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
}

.configurator-info:hover
{
    cursor: pointer;

    color: #878b8f;
}

.configurator-info2
{
    display: inline-block;

    width: 48px;
    height: 48px;

    background-color: #ffffff;
    border-radius: 50%;

    color: #101820;
}

.configurator-info-text2
{
    font-family: ClanPro-Medium;
    font-size: 22px;
    -webkit-font-smoothing: antialiased;

    text-align: center;

    padding-top: 11px;
}

.configurator-dropdown
{
    position: relative;
    
    float: left;
}

.configurator-dropdown:hover .configurator-dropdown-content
{
    display: block;
}

.configurator-dropdown:hover .configurator-dropdown-button
{
    color: #878b8f;
}

.configurator-dropdown:hover .configurator-dropdown-description
{
    color: #878b8f;
}

.configurator-dropdown-mobile
{
    position: relative;
    
    float: left;
}

.configurator-dropdown-seperator
{
    position: relative;
    
    float: left;

    width: 3px;
    height: 54px;
    
    background-color: #e4eaed;

    border-top-style: solid;
    border-top-color: #ffffff;
    border-top-width: 10px;

    border-bottom-style: solid;
    border-bottom-color: #ffffff;
    border-bottom-width: 10px;

    border-left-style: solid;
    border-left-color: #ffffff;
    border-left-width: 1px;

    border-right-style: solid;
    border-right-color: #ffffff;
    border-right-width: 1px;
}

.configurator-dropdown-button
{
    padding-left: 16px;
    padding-right: 16px;
    height: 54px;
    z-index: auto;

    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    -webkit-font-smoothing: antialiased;

    text-align: left;
    color: #000000;

    background-color: #ffffff;

    border: none;

    /* Safari fix */
    margin-left: 0px;
    margin-right: 0px;
}

.configurator-dropdown-button::after
{
    content: "\e915";

    display: inline-block;

    padding-left: 16px;

    font-family: Icons;
    font-size: 14px;
    text-align: left;
}

.configurator-dropdown-button:hover
{
    position:relative;
    z-index: 1;
}

.configurator-dropdown-button-content
{
    display: inline-block;

    padding-top: 16px;
}

.configurator-dropdown-button-content-empty
{
    display: inline-block;
    width: 80px;
}

.configurator-dropdown-button-mobile
{
    padding-left: 16px;
    padding-right: 16px;
    height: 54px;
    z-index: auto;

    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    -webkit-font-smoothing: antialiased;

    text-align: left;
    color: #000000;

    background-color: #ffffff;

    border: none;

    /* Safari fix */
    margin-left: 0px;
    margin-right: 0px;
}

.configurator-dropdown-description
{
    position: absolute;
    top: 16px;
    left: 16px;

    padding: 0;
    margin: 0;

    font-family: ClanPro-News;
    font-size: 10px;
    font-weight: 500;
    line-height: 6px;
    -webkit-font-smoothing: antialiased;

    text-align: left;
    color: #000000;
}

.configurator-dropdown-content
{
    z-index: 3;

    overflow-y: scroll;

    display: none;
    position: absolute;

    width: 304px;
    max-height: 100%;

    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;

    background-color: #ffffff;
    color: #878b8f;

    float: left;

    font-family: ClanPro-News;
    font-size: 11px;
    -webkit-font-smoothing: antialiased;

    box-shadow: 0px 24px 24px 0px rgb(0, 0, 0, 0.5);
}

.configurator-dropdown-content-info
{
    padding-top: 8px;
    padding-bottom: 8px;

    color: #878b8f;
}

.configurator-dropdown-content-seperator
{
    padding-top: 16px;
    padding-bottom: 16px;

    color: #878b8f;
}

.configurator-dropdown-content-items
{
    padding-top: 8px;
    padding-bottom: 0px;
    
    color: #878b8f;
}

.configurator-dropdown-content-mobile
{
    z-index: 3;

    overflow-y: scroll;

    display: inline-block;
    position: absolute;

    width: 304px;
    max-height: calc(100vh - 200px);
    
    top: 16px;
    left: 16px;

    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;

    background-color: #ffffff;
    color: #878b8f;

    font-family: ClanPro-News;
    font-size: 11px;
    -webkit-font-smoothing: antialiased;
}

.configurator-dropdown-content-mobile-icon
{
    display: inline-block;
    position: relative;

    margin-right: 6px;
    top: 3px;

    width: 10px;
    height: 16px;

    background-image: url('../image/icon-chevron-left.png');
    background-repeat: no-repeat;
    background-size: 10px 16px;
    background-position: center;
}

.configurator-dropdown-back
{
    margin-top: 8px;
    margin-bottom: 8px;

    color: #d92a1c;

    font-size: 14px;

    text-decoration: none;
}

.configurator-dropdown-back:hover
{
    cursor: pointer;
    text-decoration: underline;
}

.configurator-dropdown-item
{
    position: relative;

    width: 264px;
    height: 64px;

    border: none;
    padding: 0;

    /* Safari fix */
    margin-left: 0px;
    margin-right: 0px;
}

.configurator-dropdown-item:hover
{
    cursor: pointer;
}

.configurator-dropdown-item:hover .configurator-dropdown-item-border
{
    opacity: 1;
}

.configurator-dropdown-item-multi
{
    position: relative;

    width: 264px;
    height: 64px;

    margin-bottom: 4.15px;

    border: none;
    padding: 0;
}

.configurator-dropdown-item-image
{
    width: 100%;
    height: 100%;
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
}

.configurator-dropdown-item-color
{
    width: 100%;
    height: 100%;
}

.configurator-dropdown-item-border
{
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    border: 4px solid #da291c;
    
    transition: all .25s ease-out;
}

.configurator-dropdown-item-border-selected
{
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    border: 4px solid #da291c;
}

.configurator-dropdown-item-label
{
    position: absolute;
    right: 0;
    bottom: -4.15px;

    padding-left: 11px;
    padding-right: 11px;
    padding-top: 7px;

    background-color: #ffffff;

    font-family: ClanPro-Medium;
    font-size: 11px;
    font-weight: 500;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    display: table-cell;
    vertical-align: bottom;

    color: #000000;
}

.configurator-dropdown-item-checkmark
{
    height: 9px;
    padding-right: 4px;
}

.configurator-dropdown-item-multi-label
{
    position: absolute;
    right: 0;
    bottom: 0;

    padding-left: 11px;
    padding-right: 0px;
    padding-top: 0px;

    background-color: #ffffff;

    font-family: ClanPro-Regular;
    font-size: 11px;
    font-weight: 700;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    display: table-cell;
    vertical-align: bottom;

    color: #000000;
}

.configurator-dropdown-item-multi-button
{
    font-family: ClanPro-News;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    color: #FFFFFF;

    border: none;

    background-color: #d92a1c;

    display: block;
    position: relative;
    width: 48px;
    height: 32px;

    /* Safari fix */
    margin-left: 0px;
    margin-right: 0px;

    float: right;

    font-size: 22px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.configurator-dropdown-item-multi-button:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #101820;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.configurator-dropdown-item-multi-button:hover
{
    color: #fff;

    cursor: pointer;
}
  
.configurator-dropdown-item-multi-button:hover:after
{
    width: 100%;
}

.configurator-dropdown-item-multi-button span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.configurator-dropdown-item-multi-button-placeholder
{
    font-family: ClanPro-News;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    color: #FFFFFF;

    border: none;

    background-color: #d92a1c;

    display: block;
    position: relative;
    width: 48px;
    height: 32px;

    font-size: 22px;

    float: right;

    opacity: 0.15;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.configurator-dropdown-item-multi-counter
{
    width: 48px;
    height: 24px;
    padding-top: 8px;

    font-family: ClanPro-News;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    color: #d92a1c;

    float: right;
}

.configurator-dropdown-item-multi-counter-placeholder
{
    width: 48px;
    height: 24px;
    padding-top: 8px;

    font-family: ClanPro-News;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    color: #d92a1c;

    float: right;

    opacity: 0.25;
}

.configurator-dropdown-item-multi-text
{
    padding-top: 3.75px;
    padding-right: 11px;

    font-family: ClanPro-Medium;
    font-size: 11px;
    font-weight: 500;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: left;

    color: #000000;

    float: right;
}

.configurator-dropdown-item-select-button-large
{
    font-family: ClanPro-News;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    color: #FFFFFF;

    border: none;

    background-color: #d92a1c;

    display: block;
    position: relative;
    width: 264px;
    height: 32px;

    /* Safari fix */
    margin-left: 0px;
    margin-right: 0px;

    font-size: 14px;

    float: left;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}

.configurator-dropdown-item-select-button-large:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #101820;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.configurator-dropdown-item-select-button-large:hover
{
    color: #fff;

    cursor: pointer;
}
  
.configurator-dropdown-item-select-button-large:hover:after
{
    width: 100%;
}

.configurator-dropdown-item-select-button-large span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.configurator-dropdown-item-select-button
{
    font-family: ClanPro-News;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    color: #FFFFFF;

    border: none;

    background-color: #d92a1c;

    display: block;
    position: relative;
    width: 96px;
    height: 32px;

    font-size: 14px;

    float: left;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}

.configurator-dropdown-item-select-button:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    
    background-color: #101820;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.configurator-dropdown-item-select-button:hover
{
    color: #fff;

    cursor: pointer;
}
  
.configurator-dropdown-item-select-button:hover:after
{
    width: 100%;
}

.configurator-dropdown-item-select-button span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.configurator-dropdown-item-select-button-placeholder
{
    font-family: ClanPro-News;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    color: #FFFFFF;

    border: none;

    background-color: #d92a1c;

    display: block;
    position: relative;
    width: 64px;
    height: 32px;

    font-size: 14px;

    float: left;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    opacity: 0.15;
}

.configurator-dropdown-item-select-button-check-container
{
    border-style: solid;
    border-color: #d92a1c;
    border-width: 1px;

    display: block;
    position: relative;
    width: 94px;
    height: 30px;

    float: left;
}

.configurator-dropdown-item-select-button-check
{
    display: block;
    position: relative;
    padding-top: 6px;

    margin: auto;
}

.configurator-dropdown-item-mobile-title
{
    font-family: ClanPro-News;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    color: #000000;

    padding-top: 8px;
}