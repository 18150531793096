.warning
{
    position: absolute;
    top: 40%;
    width: calc(100% - 16px);

    display: flex;
    justify-content: center;
    align-items: center;
}

.warning-content
{
    display: flex;
    width: 320px;

    padding: 24px;

    font-family: ClanPro-News;
    font-size: 14px;
    text-align: left;
    -webkit-font-smoothing: antialiased;

    color: #000000;
    background-color: #f1b931;

    border-radius: 8px;
}
  
.warning-content:before
{
    content: "\e929";

    padding-right: 8px;

    font-family: Icons;
    font-size: 14px;
    text-align: left;
}