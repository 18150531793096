.footer-container
{
    width: 100%;
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 32px;
    padding-bottom: 32px;
    
    background-color: #ffffff;
}

@media (max-width: 576px)
{
    .footer-container
    {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.footer-title
{
    padding-top: 25px;
    padding-bottom: 25px;

    font-family: ClanPro-Medium;
    font-size: 24px;
    font-weight: 600;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;

    color: #101820;
}

.footer-configuration
{
    padding-bottom: 25px;
}

.footer-column
{
    float: left;
    width: 50%;
}

.footer-function-column
{
    float: left;
    width: 50%;
}

@media (max-width: 768px)
{
    .footer-function-column
    {
        width: 100%;
    }
}

.footer-text
{
    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    line-height: 24px;

    color: #878b8f;
}

.footer-text-dark
{
    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    line-height: 24px;

    color: #101820;
}

.footer-stone-image
{
    margin-right: 12px;
    margin-bottom: 12px;
    height: 96px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
}

.footer-button-download
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    line-height: 16px;

    color: #FFFFFF;

    border: none;

    background-color: #d92a1c;

    display: block;
    position: relative;
    height: 48px;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;

    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 19px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.footer-button-download:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #101820;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.footer-button-download:hover
{
    color: #fff;

    cursor: pointer;
}
  
.footer-button-download:hover:after
{
    width: 100%;
}

.footer-button-download span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.footer-button-download span::before
{
    content: "\e919";

    width: 28px;
    height: 24px;

    padding-right: 10px;

    font-family: Icons;
    font-size: 14px;
    text-align: left;
}

.footer-button-order
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: left;
    line-height: 16px;

    color: #FFFFFF;

    border: none;

    background-color: #788490;

    display: block;
    position: relative;
    height: 48px;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;

    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 19px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.footer-button-order:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #d92a1c;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.footer-button-order:hover
{
    color: #fff;

    cursor: pointer;
}
  
.footer-button-order:hover:after
{
    width: 100%;
}

.footer-button-order span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.footer-button-order span::after
{
    content: "\e91b";

    float: right;

    font-family: Icons;
    font-size: 14px;
    text-align: left;
}

.footer-help
{
    padding: 20px;

    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    line-height: 24px;

    background-color: #e4eaed;

    color: #101820;
}

.footer-help-link
{
    text-decoration: underline;
    color: #101820;
}

.footer-help-link:hover
{
    cursor: pointer;
}

.footer-help-link-color
{
    text-decoration: underline;
    color: #d92a1c;
}

.footer-help-link-color:hover
{
    cursor: pointer;
}

.footer-calculator
{
    margin-bottom: 25px;
    padding: 20px;

    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    line-height: 24px;

    background-color: #edf1f9;

    color: #101820;
}