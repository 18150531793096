body
{
    margin: 0;
    padding: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body
{
    width: 100%;

    margin: 0;
    padding: 0;
}

.body-configurator
{
    width: 100%;
}

.body-footer
{
    width: 100%;
}

@font-face
{
    font-family: 'ClanPro-Regular';
    src:  url('../fonts/ClanPro-Regular.woff2') format('woff2'),
          url('../fonts/ClanPro-Regular.woff') format('woff');
}

@font-face
{
    font-family: 'ClanPro-Medium';
    src:  url('../fonts/ClanPro-Medium.woff2') format('woff2'),
          url('../fonts/ClanPro-Medium.woff') format('woff');
}

@font-face
{
    font-family: 'ClanPro-Book';
    src:  url('../fonts/ClanPro-Book.woff2') format('woff2'),
          url('../fonts/ClanPro-Book.woff') format('woff');
}

@font-face
{
    font-family: 'ClanPro-News';
    src:  url('../fonts/ClanPro-News.woff2') format('woff2'),
          url('../fonts/ClanPro-News.woff') format('woff');
}

@font-face
{
    font-family: 'ClanPro-Bold';
    src:  url('../fonts/ClanPro-Bold.woff2') format('woff2'),
          url('../fonts/ClanPro-Bold.woff') format('woff');
}

@font-face
{
    font-family: 'Icons';
    src:  url('../fonts/icomoon.woff2') format('woff2'),
          url('../fonts/icomoon.woff') format('woff');
}

.clearfix:after
{
    content: "";
    display: block;
    clear: both;
}

::-webkit-scrollbar
{
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb
{
    background: #534a62;
}

::-webkit-scrollbar-track
{
    background: #ffffff;
}

button:focus
{
    box-shadow: none !important;
    outline: none !important;
    outline-offset: none !important;
}