.popup
{
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;

    animation-name: popup-fadein;
    animation-duration: 0.5s;
    animation-iteration-count: 1;

    z-index: 3;
}

@keyframes popup-fadein
{
    0%   {background-color: rgba(0, 0, 0, 0);}
    100% {background-color: rgba(0, 0, 0, 0.5);}
}

.popup-inner
{
    position: relative;

    padding-top: 48px;
    padding-bottom: 32px;

    width: 100%;
    max-width: 640px;

    background-color: #ffffff;

    animation-name: popup-inner-fadein;
    animation-duration: 0.5s;
    animation-iteration-count: 1;

    z-index: 4;
}

@keyframes popup-inner-fadein
{
    0%   {opacity: 0%;}
    100% {opacity: 100%;}
}

.popup-close
{
    position: absolute;
    top: 10px;
    right: 10px;

    width: 40px;
    height: 40px;

    background-color: #ffffff;
    background-image: url('../image/icon-cross.png');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: center;
}

.popup-close:hover
{
    opacity: 0.6;

    cursor: pointer;
}

.popup-container
{
    overflow-y: scroll;
    max-height: 700px;

    padding-left: 32px;
    padding-right: 32px;
}

.popup-title
{
    font-family: ClanPro-Medium;
    font-size: 28px;
    -webkit-font-smoothing: antialiased;

    text-align: left;
    color: #101820;

    margin-top: 0px;
}

@media screen and (max-width: 480px)
{
    .popup-title
    {
        font-size: 20px;
    }
}

.popup-text
{
    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    -webkit-font-smoothing: antialiased;

    text-align: left;
    color: #788490;
}

.popup-text span
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;

    color: #101820;
}

.popup-text-hyperlink
{
    display: inline-block;

    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    -webkit-font-smoothing: antialiased;
    text-decoration: underline;

    text-align: left;
    color: #788490;
}

.popup-text-hyperlink:hover
{
    color: #000000;
    cursor: pointer;
}

.popup-text-form
{
    padding-top: 12px;
    
    font-family: ClanPro-News;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    -webkit-font-smoothing: antialiased;

    text-align: left;
    color: #788490;
}

.popup-button
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    line-height: 15px;
    -webkit-font-smoothing: antialiased;

    color: #101820;

    border: none;

    background-color: #ffffff;

    display: block;
    width: min-content;
    height: 46px;
    position: relative;

    float: left;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}

.popup-button:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #d92a1c;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.popup-button:hover
{
    color: #fff;

    cursor: pointer;
}
  
.popup-button:hover:after
{
    width: 100%;
}

.popup-button span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.popup-button span::before
{
    content: "\e91b";

    padding-right: 6px;

    font-family: Icons;
    font-size: 14px;
    text-align: left;
}

.popup-button-alt
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    line-height: 15px;
    -webkit-font-smoothing: antialiased;

    color: #ffffff;

    border: none;

    background-color: #d92a1c;

    display: block;
    width: min-content;
    height: 46px;
    position: relative;

    float: left;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}

.popup-button-alt:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #101820;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.popup-button-alt:hover
{
    color: #fff;

    cursor: pointer;
}
  
.popup-button-alt:hover:after
{
    width: 100%;
}

.popup-button-alt span
{
    position: relative;
    z-index: 2;
    
    white-space: nowrap;
}

.popup-button-alt span::before
{
    content: "\e91b";

    padding-right: 6px;

    font-family: Icons;
    font-size: 14px;
    text-align: left;
}