.view-container
{
    display: absolute;
    top: 0px;

    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;

    background-color: #ffffff;
}

.view-intro-items-container
{
    width: 100%;
    height: 100vh;

    padding-left: 30px;
    padding-right: 30px;

    overflow-y: scroll;

    background-color: #ffffff;
}

.view-zoomin
{
    display: block;
    position: absolute;
    left: 16px;
    width: 56px;
    height: 73px;

    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;

    border: none;

    background-color: #ffffff;
    background-image: url('../image/button-plus.png');
    background-repeat: no-repeat;
    background-size: 56px 73px;
    background-position: left;

    transition: all .25s ease-out;
}

.view-zoomin:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #d92a1c;
    background-image: url('../image/button-plus-hover.png');
    background-repeat: no-repeat;
    background-size: 56px 73px;
    background-position: left;

    transition: all .25s ease-out;
}
  
.view-zoomin:hover:after
{
    width: 100%;
}

.view-zoomin:hover
{
    cursor: pointer;
}

.view-zoomout
{
    display: block;
    position: absolute;
    left: 72px;
    width: 56px;
    height: 73px;

    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;

    border: none;

    background-color: #ffffff;
    background-image: url('../image/button-minus.png');
    background-repeat: no-repeat;
    background-size: 56px 73px;
    background-position: left;

    transition: all .25s ease-out;
}

.view-zoomout:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #d92a1c;
    background-image: url('../image/button-minus-hover.png');
    background-repeat: no-repeat;
    background-size: 56px 73px;
    background-position: left;

    transition: all .25s ease-out;
}
  
.view-zoomout:hover:after
{
    width: 100%;
}

.view-zoomout:hover
{
    cursor: pointer;
}

.view-mode-photo
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    line-height: 18px;

    color: #FFFFFF;

    border: none;

    background-color: #d92a1c;

    display: block;
    position: absolute;
    left: 128px;
    width: 224px;
    height: 73px;

    padding-left: 0px;
    padding-right: 112px;
    padding-top: 15px;
    padding-bottom: 15px;

    margin-left: 0px;
    margin-right: 0px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    
    /*
    background-repeat: no-repeat;
    background-size: 112px auto;
    background-position: right;
    */
}

.view-mode-photo:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    color: #fff;

    background-color: #101820;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.view-mode-photo:hover
{
    color: #fff;

    cursor: pointer;
}
  
.view-mode-photo:hover:after
{
    width: 50%;
}

.view-mode-photo span
{
    position: relative;
    z-index: 2;
}

.view-mode-photo-background
{
    position: absolute;
    
    right: 0;
    top: 0;
    width: 112px;
    height: 73px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.view-mode-photo-mobile
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    line-height: 18px;

    color: #FFFFFF;

    border: none;

    background-color: #d92a1c;

    display: block;
    position: absolute;
    left: 128px;
    width: 100px;
    height: 73px;

    padding-left: 0px;
    padding-right: 0px;
    padding-top: 15px;
    padding-bottom: 15px;

    margin-left: 0px;
    margin-right: 0px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    background-size: cover;
    background-position: 50% 50%;
}

.view-mode-photo-mobile:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    color: #fff;

    background-color: #101820;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.view-mode-photo-mobile:hover
{
    color: #fff;

    cursor: pointer;
}
  
.view-mode-photo-mobile:hover:after
{
    width: 100%;
}

.view-mode-photo-mobile span
{
    position: relative;
    z-index: 2;
}

.view-photo-carrousel-container
{
    position: absolute;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    width: 100%;
    height: 72px;
    padding: 12px;
    padding-right: 0px;

    left: 0px;

    background-color: #ffffff;
}

.view-photo-carrousel-thumbnail
{
    position: relative;

    display: inline-block;
    width: 93px;
    height: 48px;
    margin-right: 12px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    background-repeat: no-repeat;
    background-size: cover;
    background-size: 93px 48px;
    background-position: center;
}

.view-photo-carrousel-thumbnail:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #d92a1c;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}

.view-photo-carrousel-thumbnail:hover
{
    color: #fff;

    cursor: pointer;
}
  
.view-photo-carrousel-thumbnail:hover:after
{
    width: 7%;
}

.view-photo-carrousel-thumbnail-selected
{
    position: relative;

    display: inline-block;
    width: 93px;
    height: 48px;
    margin-right: 12px;

    background-repeat: no-repeat;
    background-size: cover;
    background-size: 93px 48px;
    background-position: center;
}

.view-photo-carrousel-thumbnail-selected:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 7%;
    height: 100%;

    background-color: #d92a1c;
}

.view-photo-previous
{
    position: absolute;
    left: 0px;
    
    display: block;
    width: 48px;
    height: 48px;

    color: #101820;

    border: none;

    background-color: #000000;
    background-image: url('../image/button-arrow-left.png');
    background-repeat: no-repeat;
    background-size: 48px 48px;
    background-position: left;

    transition: all .25s ease-out;
}

.view-photo-previous:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-image: url('../image/button-arrow-left-alt.png');
    background-repeat: no-repeat;
    background-size: 48px 48px;
    background-position: left;

    transition: all .25s ease-out;
}
  
.view-photo-previous:hover
{
    color: #fff;

    cursor: pointer;
}

.view-photo-previous:hover:after
{
    width: 100%;
}

.view-photo-next
{
    position: absolute;
    right: 0px;
    
    display: block;
    width: 48px;
    height: 48px;

    border: none;

    background-color: #000000;
    background-image: url('../image/button-arrow-right.png');
    background-repeat: no-repeat;
    background-size: 48px 48px;
    background-position: left;
    
    transition: all .25s ease-out;
}

.view-photo-next:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-image: url('../image/button-arrow-right-alt.png');
    background-repeat: no-repeat;
    background-size: 48px 48px;
    background-position: left;

    transition: all .25s ease-out;
}
  
.view-photo-next:hover
{
    color: #fff;

    cursor: pointer;
}

.view-photo-next:hover:after
{
    width: 100%;
}

.view-photo-zoomin
{
    display: block;
    position: absolute;
    bottom: 72px;
    
    left: 0px;
    width: 56px;
    height: 73px;

    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;

    border: none;

    background-color: #ffffff;
    background-image: url('../image/button-plus.png');
    background-repeat: no-repeat;
    background-size: 56px 73px;
    background-position: left;

    transition: all .25s ease-out;
}

.view-photo-zoomin:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #d92a1c;
    background-image: url('../image/button-plus-hover.png');
    background-repeat: no-repeat;
    background-size: 56px 73px;
    background-position: left;

    transition: all .25s ease-out;
}
  
.view-photo-zoomin:hover:after
{
    width: 100%;
}

.view-photo-zoomin:hover
{
    cursor: pointer;
}

.view-photo-zoomout
{
    display: block;
    position: absolute;
    bottom: 72px;
    left: 0px;
    width: 56px;
    height: 73px;

    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;

    border: none;

    background-color: #ffffff;
    background-image: url('../image/button-minus.png');
    background-repeat: no-repeat;
    background-size: 56px 73px;
    background-position: left;

    transition: all .25s ease-out;
}

.view-photo-zoomout:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    background-color: #d92a1c;
    background-image: url('../image/button-minus-hover.png');
    background-repeat: no-repeat;
    background-size: 56px 73px;
    background-position: left;

    transition: all .25s ease-out;
}
  
.view-photo-zoomout:hover:after
{
    width: 100%;
}

.view-photo-zoomout:hover
{
    cursor: pointer;
}

.view-mode-2d
{
    font-family: ClanPro-Medium;
    font-size: 14px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    line-height: 18px;

    color: #FFFFFF;

    border: none;

    background-color: #d92a1c;

    display: block;
    position: absolute;
    bottom: 72px;
    left: 56px;
    width: 112px;
    height: 73px;
    
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 15px;
    padding-bottom: 15px;

    margin-left: 0px;
    margin-right: 0px;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.view-mode-2d:after
{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;

    color: #fff;

    background-color: #101820;

    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
}
  
.view-mode-2d:hover
{
    color: #fff;

    cursor: pointer;
}
  
.view-mode-2d:hover:after
{
    width: 100%;
}

.view-mode-2d span
{
    position: relative;
    z-index: 2;
}